import {

  TextField,
} from 'react-admin';
import React from 'react';


export const TitleField = ({
  record, ...rest
}) => {
  const name = `Слот №${record.type}`;
  return (
    <TextField
      {...rest}
      record={{ ...record, type: name }}
    />
  );
};
