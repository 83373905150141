import React from 'react';
import { get } from 'lodash';

import { EMPTY_TEXT } from 'components';


export const CategoryField = ({ record, source, allCategories }) => {
  const categoryId = get(record, source);

  if (!record || !source || !get(record, source)) return <div>{EMPTY_TEXT}</div>;

  const category = allCategories.find((item) => item.id === categoryId);

  return (
    <div>
      {category?.title ?? EMPTY_TEXT}
    </div>
  );
};
