import React from 'react';

import BranchField from '../BranchField';
import { useCompanies } from '../../hooks/useCompanies';

export const CompaniesField = () => {
  const {
    companies,
  } = useCompanies();

  return (
    <>
      <BranchField choises={companies} />
    </>
  );
};
