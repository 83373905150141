
import {
  useQueryWithStore,
} from 'react-admin';

export const useGetCategories = (type) => {
  const { data: categories, error, loading } = useQueryWithStore({
    type: 'getList',
    resource: 'categories',
    payload: {
      filter: {
        type,
      },
      pagination: { page: 0, perPage: 20 },
      sort: { field: 'title', order: 'ASC' },
    },
  });

  return { categories, error, loading };
};
