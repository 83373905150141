import JSONServer from 'ra-data-json-server';

import handleRequest from 'helpers/handleRequest';


const dataProvider = JSONServer(process.env.REACT_APP_API_URL);

export default ({
  ...dataProvider,
  create: async (resource, params) => {
    const changedParams = { ...params };
    return handleRequest(dataProvider.create, resource, changedParams);
  },
  update: async (resource, params) => {
    const changedParams = { ...params };
    return handleRequest(dataProvider.update, resource, changedParams);
  },
});
