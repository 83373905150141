import handleBase64 from 'helpers/handleBase64';

export default async (request, resource, params) => {
  const { data } = params;
  const {
    image, previewImage, previewImageEn, imageEn,
  } = data;

  try {
    const imageBase64 = image ? await handleBase64(image) : undefined;
    const previewImageBase64 = previewImage ? await handleBase64(previewImage) : undefined;
    const previewImageEnBase64 = previewImageEn ? await handleBase64(previewImageEn) : undefined;
    const imageEnBase64 = imageEn ? await handleBase64(imageEn) : undefined;


    return request(resource, {
      ...params,
      data: {
        ...params.data,
        ...(image ? { image: imageBase64 } : {}),
        ...(previewImage ? { previewImage: previewImageBase64 } : {}),
        ...(imageEn ? { imageEn: imageEnBase64 } : {}),
        ...(previewImageEn ? { previewImageEn: previewImageEnBase64 } : {}),
      },
    });
  } catch (error) {
    throw new Error(error);
  }
};
