import { useQueryWithStore } from 'react-admin';

export const useCompanies = () => {
  const { data: companies = [], error } = useQueryWithStore({
    type: 'getMany',
    resource: 'announcements/companies',
    payload: {},
  });

  return {
    companies: companies.map(({ id, ...company }) => ({ id: id.toString(), ...company })),
    error,
  };
};
