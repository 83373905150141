import {
  Create,
  required,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
} from 'react-admin';
import React from 'react';

import StockField from '../StockField';
import { CountriesAndCompaniesFields } from '../CountriesAndCompaniesFields';

const SendNotification = (props) => (
  <Create
    {...props}
    resource="notifications/everybody"
    successMessage="Оповещения отправлены успешно"
    title="Отправить оповещение"
  >
    <SimpleForm
      redirect={false}
      toolbar={(
        <Toolbar>
          <SaveButton
            label="Отправить"
            redirect={false}
            submitOnEnter
          />
        </Toolbar>
            )}
    >
      <TextInput
        label="Заголовок"
        source="title"
        validate={required()}
        fullWidth
      />
      <TextInput
        label="Заголовок En"
        source="titleEn"
        validate={required()}
        fullWidth
      />
      <TextInput
        label="Сообщение"
        rows={3}
        rowsMax={3}
        source="message"
        validate={required()}
        fullWidth
        multiline
      />
      <TextInput
        label="СообщениеEn"
        rows={3}
        rowsMax={3}
        source="messageEn"
        validate={required()}
        fullWidth
        multiline
      />
      <CountriesAndCompaniesFields />
      <StockField />
    </SimpleForm>
  </Create>
);

export default SendNotification;
