export const OPEN_REMOTE_VISIT = 'OpenRemoteVisit';
export const OPEN_LOCAL_VISIT = 'OpenLocalVisit';
export const OPEN_URL = 'OpenUrl';
export const OPEN_CHAT = 'OpenChat';
export const OPEN_SHOP = 'OpenShop';
export const NO_ACTION = 'NoAction';
export const CALL = 'Call';
export const OPEN_PROMOTION = 'open-promotion'; // sync with notification

export const CHOICES = ([{
  id: OPEN_REMOTE_VISIT,
  name: 'Открыть форму записи выезда на дом',
}, {
  id: OPEN_LOCAL_VISIT,
  name: 'Открыть форму записи',
}, {
  id: OPEN_URL,
  name: 'Открыть ссылку',
}, {
  id: OPEN_CHAT,
  name: 'Открыть чат',
}, {
  id: OPEN_SHOP,
  name: 'Открыть магазин',
}, {
  id: NO_ACTION,
  name: 'Без действия',
}, {
  id: CALL,
  name: 'Позвонить',
}, {
  id: OPEN_PROMOTION,
  name: 'Открыть акцию',
}]);
