import {
  BooleanInput,
  FormDataConsumer,
  SelectArrayInput,
  required,
} from 'react-admin';
import React from 'react';
import { Grid } from '@material-ui/core';

const CountryField = ({ choises }) => (
  <Grid
    alignItems="center"
    spacing={3}
    container
  >
    <Grid item>
      <BooleanInput
        label="Все страны"
        source="allCountries"
      />
    </Grid>
    <Grid
      item
      xs
    >
      <FormDataConsumer>
        {
            ({ formData, ...rest }) => !formData.allCountries && (
              <SelectArrayInput
                {...rest}
                choices={choises}
                label="Страна"
                optionText="title"
                optionValue="id"
                source="countries"
                style={{ width: '100%' }}
                validate={required()}
              />
            )
          }
      </FormDataConsumer>
    </Grid>
  </Grid>
);

export default CountryField;
