import { useQueryWithStore } from 'react-admin';
import { useMemo } from 'react';
import { groupBy, uniqBy } from 'lodash';

export const COUNTRY_ID = {
  Russia: '1',
  UAE: '9',
};

export const COUNTRIES = [
  {
    title: 'Россия',
    id: COUNTRY_ID.Russia,
  },
  {
    title: 'ОАЭ',
    id: COUNTRY_ID.UAE,
  },
];

export const useCountriesAndCompanies = () => {
  const { data = [], error } = useQueryWithStore({
    type: 'getMany',
    resource: 'promotions/companies',
    payload: {},
  });

  const companies = useMemo(() => data.map((d) => ({
    ...d,
    id: d.id.toString(),
  })), [data]);

  const companiesByCountryId = useMemo(() => groupBy(companies, 'country_id'), [companies]);

  const countries = useMemo(() => uniqBy(companies, 'country_id').map((company) => ({
    title: company.country,
    id: company.country_id.toString(),
  })), [companies]);

  return {
    companies,
    companiesByCountryId,
    countries,
    error,
  };
};
