import { AutocompleteInput } from 'react-admin';
import React from 'react';

import { EMPTY_ID } from '../../../constants';


export const CategorySelect = ({ categories, ...rest }) => {
  if (!categories.length) {
    return <div>Нет родительских категорий</div>;
  }

  const parsingStringToArray = (val) => (val !== EMPTY_ID && val ? val : undefined);
  const formattingArrayToString = (value) => (!value ? EMPTY_ID : value);

  return (
    <AutocompleteInput
      {...rest}
      choices={[{ id: EMPTY_ID, title: 'Нет' }, ...categories]}
      format={formattingArrayToString}
      label="Категории"
      optionText="title"
      optionValue="id"
      parse={parsingStringToArray}
    />
  );
};
