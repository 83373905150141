import {
  List,
  Datagrid,
} from 'react-admin';
import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TitleField } from '../components/TitleField';

const useStyles = makeStyles({
  content: {
    marginTop: 16,
  },
});


const BannersList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      actions={false}
      bulkActionButtons={false}
      classes={{ content: classes.content }}
      exporter={false}
      title="Баннеры"
    >
      <Datagrid
        {...props}
        rowClick="edit"
      >
        <TitleField
          label="Название"
          source="type"
        />
      </Datagrid>
    </List>
  );
};

export default BannersList;
