import {
  useQueryWithStore,
  Error,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  required,
} from 'react-admin';
import React from 'react';
import { Grid } from '@material-ui/core';

import { OPEN_PROMOTION } from '../AnnouncementTypeField/constants';

const StockField = () => {
  const { data, error } = useQueryWithStore({
    type: 'getMany',
    resource: 'promotions?',
    payload: { ids: [] },
  });

  const mappedData = data && data.length ? data.map((d) => ({
    ...d,
    id: d.id.toString(),
  })) : [];

  if (error) {
    return <Error />;
  }

  return (
    <Grid
      alignItems="center"
      spacing={3}
      container
    >
      <Grid item>
        <FormDataConsumer>
          {/* formData.type use for announcement */}
          {({ formData }) => !formData.type && (
          <BooleanInput
            label="Действие по клику"
            source="withAction"
          />
          )}
        </FormDataConsumer>
      </Grid>
      <Grid
        item
        xs
      >
        <FormDataConsumer>
          {
            ({ formData, ...rest }) => formData.withAction && (
              <SelectInput
                {...rest}
                choices={[{
                  id: 'open-promotion',
                  name: 'Открыть акцию',
                }]}
                label="Действие"
                optionText="name"
                optionValue="id"
                source="actionType"
                style={{ width: '100%' }}
              />
            )
          }
        </FormDataConsumer>
      </Grid>
      <Grid
        sm={12}
        item
      >
        <FormDataConsumer>
          {/* formData.type use for announcement, actionType use for notification */}
          {({ formData, ...rest }) => ((formData.withAction && formData.actionType === 'open-promotion') || formData.type === OPEN_PROMOTION) && (
          <SelectInput
            {...rest}
            choices={mappedData}
            label="Акция"
            optionText="title"
            optionValue="id"
            source="promotionToOpen"
            style={{ width: '100%' }}
            validate={required()}
          />
          )}
        </FormDataConsumer>
      </Grid>
    </Grid>
  );
};

export default StockField;
