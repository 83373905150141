import {
  TextInput,
  ImageInput,
  ImageField,
  required,
  maxLength,
} from 'react-admin';
import React from 'react';

import handleFormat from 'helpers/handleFormat';
import StockField from '../StockField';
import { CompaniesField } from '../CompaniesField';
import AnnouncementTypeField from '../AnnouncementTypeField';

const AnnouncementForm = () => (
  <>
    <TextInput
      label="Название объявления"
      source="title"
      validate={[required(), maxLength(30)]}
      fullWidth
    />

    <TextInput
      label="Текст объявления"
      rows={3}
      rowsMax={3}
      source="description"
      validate={[required(), maxLength(380)]}
      fullWidth
      multiline
    />

    <ImageInput
      accept="image/*"
      format={handleFormat}
      label="Изображение для объявления"
      placeholder={<p>Добавить изображение</p>}
      source="image"
      validate={required()}
    >
      <ImageField source="url" />
    </ImageInput>

    <CompaniesField />

    <AnnouncementTypeField />

    <StockField />
  </>
);

export default AnnouncementForm;
