import Carousel, { Modal, ModalGateway } from 'react-images';
import React, { useState } from 'react';
import { get, isArray } from 'lodash';

import styles from './ImagesField.module.scss';


const ImagesField = ({ record, source }) => {
  const data = get(record, source);

  const images = isArray(data) ? data : [data];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const onOpen = (e, index) => {
    e.stopPropagation();
    setCurrentImageIndex(index);
    setModalOpen(true);
  };

  const onClose = (e) => {
    setCurrentImageIndex(0);
    setModalOpen(false);
    e.stopPropagation();
  };

  if (!record || !images || !images.length || !data) return <div>—</div>;

  return (
    <div className={styles.wrapper}>
      {images.map((image, index) => (
        <button
          className={styles.image}
          key={image}
          onClick={(e) => {
            onOpen(e, index);
          }}
          type="button"
        >
          <img
            alt={image}
            className={styles.image}
            src={image}
          />
        </button>
      ))}
      <ModalGateway>
        {isModalOpen ? (
          <Modal
            allowFullscreen={false}
            onClose={onClose}
            closeOnEsc
          >
            <Carousel
              currentIndex={currentImageIndex}
              hideControlsWhenIdle={false}
              modalProps={{
                allowFullscreen: false,
              }}
              styles={{
                container: (base) => ({
                  ...base,
                  '& button': {
                    outline: 'none !important',
                    padding: 0,
                  },
                }),
                view: (base) => ({
                  ...base,
                  maxHeight: '700px',

                  width: '100%',
                  margin: '0 auto',
                  '& img': {
                    maxHeight: '700px',
                  },
                }),
              }}
              views={images.map((image) => ({
                source: image,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default ImagesField;
