import { useFormState } from 'react-final-form';
import React, { useMemo } from 'react';
import { flatten, isEmpty } from 'lodash';

import CountryField from '../CountryField';
import BranchField from '../BranchField';
import { COUNTRIES, useCountriesAndCompanies } from '../../hooks/useCountriesAndCompanies';

export const CountriesAndCompaniesFields = () => {
  const { values } = useFormState();

  const {
    companies,
    companiesByCountryId,
  } = useCountriesAndCompanies();

  const filteredCompanies = useMemo(() => {
    if (isEmpty(companiesByCountryId)) return [];

    if (!values.countries?.length || values.allCountries) return companies;

    return flatten(values.countries.map((countryId) => companiesByCountryId[countryId]));
  }, [values, companiesByCountryId, companies]);

  return (
    <>
      <CountryField choises={COUNTRIES} />
      {(values.allCountries || !!values.countries?.length) && (
        <BranchField choises={filteredCompanies} />
      )}
    </>
  );
};
