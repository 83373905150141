import {
  SelectInput,
  required,
  FormDataConsumer,
  TextInput,
} from 'react-admin';
import React from 'react';
import { Grid } from '@material-ui/core';

import {
  CALL,
  CHOICES,
  OPEN_URL,
} from './constants';

const AnnouncementTypeField = (props) => (
  <Grid
    spacing={3}
    container
  >
    <Grid
      item
    >
      <SelectInput
        {...props}
        choices={CHOICES}
        label="Тип"
        source="type"
        validate={required()}
      />
    </Grid>
    <Grid
      item
      xs
    >
      <FormDataConsumer>
        {
          ({ formData, ...rest }) => (formData.type === OPEN_URL || formData.type === CALL) && (
            <TextInput
              {...rest}
              label={formData.type === OPEN_URL ? 'Ссылка' : 'Номер телефона'}
              source={formData.type === OPEN_URL ? 'url' : 'phone'}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </Grid>
  </Grid>
);

export default AnnouncementTypeField;
