import {
  Edit,
  SimpleForm,
} from 'react-admin';
import React from 'react';

import Form from './Form';

const CategoryEdit = (props) => (
  <Edit
    {...props}
    component="div"
    title="Изменить"
  >
    <SimpleForm>
      <Form />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
