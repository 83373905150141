import urlParser from 'url';
import {
  TextInput,
  ImageInput,
  ImageField,
  required,
} from 'react-admin';
import React from 'react';

import handleFormat from 'helpers/handleFormat';
import TypeField from 'components/TypeField';
import { CountriesAndCompaniesFields } from '../CountriesAndCompaniesFields';

const Form = () => {
  const validateYoutubeUrl = (url) => {
    if (!url) return undefined;

    const parsedUrl = urlParser.parse(url, true);
    if (parsedUrl && parsedUrl.query && parsedUrl.query.v) return undefined;

    return 'Неверная ссылка';
  };

  return (
    <>
      <TextInput
        label="Название акции"
        source="title"
        validate={required()}
        fullWidth
      />

      <TextInput
        label="Название акции En"
        source="titleEn"
        validate={required()}
        fullWidth
      />

      <TextInput
        label="Описание акции"
        rows={3}
        rowsMax={3}
        source="description"
        validate={required()}
        fullWidth
        multiline
      />

      <TextInput
        label="Описание акции En"
        rows={3}
        rowsMax={3}
        source="descriptionEn"
        validate={required()}
        fullWidth
        multiline
      />

      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Превью акции"
        placeholder={<p>Добавить превью</p>}
        source="previewImage"
        validate={required()}
      >
        <ImageField source="url" />
      </ImageInput>

      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Превью акции En"
        placeholder={<p>Добавить превью</p>}
        source="previewImageEn"
        validate={required()}
      >
        <ImageField source="url" />
      </ImageInput>

      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Основное фото"
        placeholder={<p>Добавить основное фото</p>}
        source="image"
        validate={required()}
      >
        <ImageField source="url" />
      </ImageInput>

      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Основное фото En"
        placeholder={<p>Добавить основное фото</p>}
        source="imageEn"
        validate={required()}
      >
        <ImageField source="url" />
      </ImageInput>

      <TextInput
        label="Ссылка на видео (youtube)"
        source="videoUrl"
        validate={[validateYoutubeUrl]}
        fullWidth
        multiline
      />

      <TextInput
        label="Ссылка на видео (youtube) En"
        source="videoUrlEn"
        validate={[validateYoutubeUrl]}
        fullWidth
        multiline
      />
      <CountriesAndCompaniesFields />
      <TypeField />
    </>
  );
};

export default Form;
