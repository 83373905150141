
import { CategoryEdit, СategoriesList, CategoryCreate } from 'modules/Сategories';
import { BannerEdit, BannersList } from 'modules/Banners';
import AnnouncementsList from './components/AnnouncementsList';
import AnnouncementEdit from './components/AnnouncementEdit';
import AnnouncementCreate from './components/AnnouncementCreate';
import {
  StockCreate,
  StockEdit,
  StocksList,
  SendNotification,
} from './components';

export default [
  {
    createComponent: StockCreate,
    editComponent: StockEdit,
    listComponent: StocksList,
    name: 'promotions',
    label: 'Акции',
  },
  {
    listComponent: SendNotification,
    name: 'notifications',
    label: 'Оповещения',
  },
  {
    createComponent: CategoryCreate,
    editComponent: CategoryEdit,
    listComponent: СategoriesList,
    name: 'categories',
    label: 'Категории',
  },
  {
    editComponent: BannerEdit,
    listComponent: BannersList,
    name: 'banners',
    label: 'Баннеры',
  },
  {
    createComponent: AnnouncementCreate,
    editComponent: AnnouncementEdit,
    listComponent: AnnouncementsList,
    name: 'announcements',
    label: 'Объявления',
  },
];
