import {
  TextInput,
  ImageInput,
  ImageField,
} from 'react-admin';
import React from 'react';

import handleFormat from 'helpers/handleFormat';

const Form = (props) => {
  const { record: data } = props;

  return (
    <>
      <div>
        Слот №
        {data.type}
      </div>
      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Картинка"
        placeholder={(<p>Добавить фото</p>)}
        source="image"
      >
        <ImageField
          source="url"
        />
      </ImageInput>

      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Картинка En"
        placeholder={(<p>Добавить фото</p>)}
        source="imageEn"
      >
        <ImageField
          source="url"
        />
      </ImageInput>

      <TextInput
        label="Описание"
        rows={3}
        rowsMax={3}
        source="text"
        fullWidth
        multiline
      />

      <TextInput
        label="ОписаниеEn"
        rows={3}
        rowsMax={3}
        source="textEn"
        fullWidth
        multiline
      />
    </>
  );
};
export default Form;
