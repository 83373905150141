import {
  Edit,
  SimpleForm,
} from 'react-admin';
import React from 'react';

import AnnouncementForm from '../AnnouncementForm';

const AnnouncementEdit = (props) => (
  <Edit
    {...props}
    title="Изменить"
  >
    <SimpleForm>
      <AnnouncementForm />
    </SimpleForm>
  </Edit>
);

export default AnnouncementEdit;
