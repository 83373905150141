import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';
import React from 'react';

import AllBranchesField from '../AllBranchesField';

const StocksList = (props) => (
  <List
    {...props}
    exporter={false}
    title="Акции"
  >
    <Datagrid rowClick="edit">
      <TextField
        label="Название акции"
        source="title"
      />
      <AllBranchesField label="Вся сеть" />
    </Datagrid>
  </List>
);

export default StocksList;
