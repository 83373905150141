import {
  Create,
  SimpleForm,
} from 'react-admin';
import React from 'react';

import AnnouncementForm from '../AnnouncementForm';

const AnnouncementCreate = (props) => (
  <Create
    {...props}
    title="Создать"
  >
    <SimpleForm>
      <AnnouncementForm />
    </SimpleForm>
  </Create>
);

export default AnnouncementCreate;
