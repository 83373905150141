import { BooleanField } from 'react-admin';
import React from 'react';

const AllBranchesField = ({ record, label }) => (
  <BooleanField
    label={label}
    record={{
      ...record,
      allBranches: record.allCountries !== undefined
        ? !!record.allBranches && !!record.allCountries
        : !!record.allBranches,
    }}
    source="allBranches"
  />
);

export default AllBranchesField;
