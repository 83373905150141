import Hashes from 'jshashes';

const KEY = 'user';
const HASH = 'e50bc4937183591f6a4ad838090c0840d8f719fd71e2948a9bcc97ee81d31428';

const SHA256 = new Hashes.SHA256();

const AuthProvider = {
  login: ({ username, password }) => {
    const hash = SHA256.hex(username + password);

    if (hash === HASH) {
      return Promise.resolve(localStorage.setItem(KEY, hash));
    }
    return Promise.reject();
  },
  logout: () => (
    Promise.resolve(localStorage.removeItem(KEY))
  ),
  checkError: () => Promise.resolve(),
  checkAuth: () => (
    localStorage.getItem(KEY) === HASH ? Promise.resolve() : Promise.reject()
  ),
  getPermissions: () => Promise.resolve(),
};

export default AuthProvider;
