import {
  List,
  Datagrid,
  TextField,
  Loading,
  Error,
} from 'react-admin';
import React from 'react';
import { makeStyles } from '@material-ui/core';

import { EMPTY_TEXT } from 'components/constants';
import { ImagesField } from 'components';
import { useGetCategories } from '../hooks/useGetCategories';
import { CategoryField } from '../components/CategoryField';

const useStyles = makeStyles({
  content: {
    marginTop: 16,
  },
  imageField: {
    width: 60,
  },
});

const СategoriesList = (props) => {
  const classes = useStyles();
  const { categories, error, loading } = useGetCategories();


  if (loading) {
    return <Loading />;
  }

  if (!categories || error) {
    return <Error />;
  }

  return (
    <List
      {...props}
      classes={{ content: classes.content }}
      exporter={false}
      title="Категории"
    >
      <Datagrid
        {...props}
        rowClick="edit"
      >
        <ImagesField
          headerClassName={classes.imageField}
          label="Фото"
          sortable={false}
          source="image"
        />
        <TextField
          emptyText={EMPTY_TEXT}
          label="Название"
          sortable={false}
          source="title"
        />
        <CategoryField
          allCategories={categories}
          label="Родительские категории"
          sortable={false}
          source="categories"
        />
        <TextField
          emptyText={EMPTY_TEXT}
          label="ID"
          sortable={false}
          source="insalesId"
        />
      </Datagrid>
    </List>
  );
};

export default СategoriesList;
