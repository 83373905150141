import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
} from 'react-admin';
import React from 'react';

import Form from '../components/Form';

const BannerEdit = (props) => (
  <Edit
    {...props}
    title="Изменить"
  >
    <SimpleForm

      toolbar={(
        <Toolbar>
          <SaveButton />
        </Toolbar>
)}
    >
      <Form />
    </SimpleForm>
  </Edit>
);

export default BannerEdit;
