import {
  TextInput,
  ImageInput,
  ImageField,
  Loading,
  Error,
  required,
} from 'react-admin';
import React from 'react';

import handleFormat from 'helpers/handleFormat';
import { useGetCategories } from '../../hooks/useGetCategories';
import { CategorySelect } from '../../components/CategorySelect';

const Form = (props) => {
  const { categories, error, loading } = useGetCategories('categories');

  if (loading) {
    return <Loading />;
  }

  if (!categories || error) {
    return <Error />;
  }
  const categoriesExceptCurrent = categories.filter((category) => category.id !== props.record.id);

  return (
    <>
      <TextInput
        label="Название"
        source="title"
        validate={required()}
        fullWidth
        multiline
      />
      <TextInput
        label="Название En"
        source="titleEn"
        validate={required()}
        fullWidth
        multiline
      />
      <TextInput
        label="ID"
        source="insalesId"
        validate={required()}
        fullWidth
        multiline
      />

      <ImageInput
        accept="image/*"
        format={handleFormat}
        label="Картинка"
        placeholder={(<p>Добавить фото</p>)}
        source="image"
        validate={required()}
      >
        <ImageField
          source="url"
        />
      </ImageInput>
      <CategorySelect
        categories={categoriesExceptCurrent}
        source="categories"
      />
    </>
  );
};
export default Form;
